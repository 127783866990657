import { useCreateProjectInTeamFormInvoiceDetails } from './hooks/useCreateProjectInTeamFormInvoiceDetails';

import { SelectBillingInfoModalButton } from '../../../../../../billingInfos/components/modalButtons/SelectBillingInfoModalButton';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormFields
} from '../../CreateProjectInTeamForm.types';
import { InvoicePaymentMethods } from '../../../../../../invoices/invoicesTypes';

import { AccordionHelper } from '../../../../../../../helpers/AccordionHelper';
import { AvBillingInfoHelper } from '../../../../../../avBillingInfos/helpers/AvBillingInfoHelper';
import { BillingInfoHelper } from '../../../../../../billingInfos/helpers/BillingInfoHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { RadioButtonsField } from '../../../../../../../helpers/FormFields/RadioButtonsField';
import { TextareaAutosizeField } from '../../../../../../../helpers/FormFields/TextareaAutosizeField';

import { ProjectsPermissions } from '../../../../../projectsConstants';
import {
  billingInfosKeys,
  invoicesKeys,
  teamsKeys,
  words
} from '../../../../../../../locales/keys';
import { AvBillingInfoPaymentMethodHelper } from '../../../../../../avBillingInfos/helpers/AvBillingInfoPaymentMethodHelper';
import { useMemo } from 'react';

const paymentMethods = [InvoicePaymentMethods.WIRE, InvoicePaymentMethods.CARD];

function CreateProjectInTeamFormInvoiceDetails() {
  const {
    control,
    createProjectInTeamLoading,
    currentUser,
    selectedBillingInfo,
    selectedBillingInfoId,
    selectedTeamNanoId,
    setBillingInfoId,
    defaultTerms,
    defaultNotes
  } = useCreateProjectInTeamFormInvoiceDetails();

  return (
    <AccordionHelper i18nText={invoicesKeys.details}>
      <div className="space-y-2 mt-4">
        {/* Payment method */}
        <CheckPermissions
          action={
            ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_PAYMENT_METHOD
          }
        >
          <RadioButtonsField<CreateProjectInTeamFormData, string>
            control={control}
            disabled={
              !selectedTeamNanoId ||
              createProjectInTeamLoading ||
              !currentUser.hasPermissions(
                ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_PAYMENT_METHOD
              )
            }
            i18nLabel={invoicesKeys.paymentMethodWithColon}
            labelClassName="text-xs font-semibold"
            name={CreateProjectInTeamFormFields.PAYMENT_METHOD}
            options={paymentMethods}
            optionClassName="text-xs"
            radioButtonLabelClassName="inline-flex items-center gap-2"
          />
        </CheckPermissions>

        {/* Billing info */}
        <CheckPermissions
          action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_BILLING_INFO}
        >
          <div className="flex gap-6 mb-4">
            {/* Bill from */}
            <CheckPermissions
              action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_BILL_FROM}
            >
              <div className="flex-1">
                <div className="text-xs">
                  <AvBillingInfoHelper
                    avBillingInfo={selectedBillingInfo?.avBillingInfo}
                    i18nLabel={billingInfosKeys.billFromColon}
                    i18nWarning={
                      billingInfosKeys.receiverBillingInfoWillFillAutomaticallyAfterPayerBillingInfoIsProvided
                    }
                  />
                </div>
              </div>
            </CheckPermissions>

            <div className="flex-1">
              <div className="text-xs">
                {/* Bill to */}
                <CheckPermissions
                  action={
                    ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_BILL_TO
                  }
                >
                  <BillingInfoHelper
                    billingInfo={selectedBillingInfo}
                    i18nLabel={billingInfosKeys.billToColon}
                    i18nWarning={
                      billingInfosKeys.provideYourBillingInfoInOrderToCreateInvoiceForTheProject
                    }
                  />
                </CheckPermissions>

                <CheckPermissions
                  action={
                    ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_BILLING_INFO
                  }
                >
                  <div className="mt-1.5">
                    <SelectBillingInfoModalButton
                      companyNanoId={selectedTeamNanoId}
                      defaultBillingInfoId={selectedBillingInfoId}
                      i18nSubmitText={words.saveChanges}
                      onSubmit={setBillingInfoId}
                      withSelectForm
                      withoutReceiver={
                        !currentUser.hasPermissions(
                          ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_CHANGE_BILLING_INFO_MODAL_AV_BILLING_INFO_ID
                        )
                      }
                    />
                  </div>
                </CheckPermissions>
              </div>
            </div>
          </div>
        </CheckPermissions>

        {/* Payment method */}
        <CheckPermissions
          action={
            ProjectsPermissions.READ_CREATE_PROJECTS_IN_TEAM_PAYMENT_METHOD
          }
        >
          <AvBillingInfoPaymentMethodHelper
            paymentMethod={selectedBillingInfo?.avBillingInfo?.paymentMethod}
          />
        </CheckPermissions>

        {/* Notes */}
        <CheckPermissions
          action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_NOTES}
        >
          {(defaultNotes ||
            currentUser.hasPermissions(
              ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_NOTES_EMPTY
            )) && (
            <div>
              <TextareaAutosizeField<CreateProjectInTeamFormData>
                className="w-full text-xs resize-none bg-transparent text-gray-600 dark:text-gray-400 placeholder-gray-500 border-0 px-2 py-1 -mt-1 rounded hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-4 ring-blue-200 focus:bg-transparent"
                control={control}
                disabled={
                  !selectedBillingInfoId ||
                  createProjectInTeamLoading ||
                  !currentUser.hasPermissions(
                    ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_NOTES
                  )
                }
                i18nLabel={invoicesKeys.notesColon}
                i18nPlaceholder={invoicesKeys.addANote}
                inputWrapperClassName="mt-1 -mx-2"
                labelClassName="text-xs font-semibold"
                name={CreateProjectInTeamFormFields.NOTES}
              />
            </div>
          )}
        </CheckPermissions>

        {/* Terms */}
        <CheckPermissions
          action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TERMS}
        >
          {(defaultTerms ||
            currentUser.hasPermissions(
              ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TERMS_EMPTY
            )) && (
            <div>
              <TextareaAutosizeField<CreateProjectInTeamFormData>
                className="w-full text-xs resize-none bg-transparent text-gray-600 dark:text-gray-400 placeholder-gray-500 border-0 px-2 py-1 -mt-1 rounded hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-4 ring-blue-200 focus:bg-transparent"
                control={control}
                disabled={
                  !selectedTeamNanoId ||
                  createProjectInTeamLoading ||
                  !currentUser.hasPermissions(
                    ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_TERMS
                  )
                }
                i18nLabel={invoicesKeys.termsColon}
                i18nPlaceholder={teamsKeys.addTerms}
                inputWrapperClassName="mt-1 -mx-2"
                labelClassName="text-xs font-semibold"
                name={CreateProjectInTeamFormFields.TERMS}
              />
            </div>
          )}
        </CheckPermissions>
      </div>
    </AccordionHelper>
  );
}

export default CreateProjectInTeamFormInvoiceDetails;
