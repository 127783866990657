import { createContext, ReactNode, useContext } from 'react';

import { Currencies } from '../../../../../../../types';

import { useCreateProjectInTeamForm } from '../useCreateProjectInTeamForm';

import { AvBillingInfoNotes } from '../../../../../../avBillingInfos/avBillingInfosTypes';
import { BillingInfoID } from '../../../../../../billingInfos/billingInfosTypes';
import { CreateProjectInTeamFormContextType } from './useCreateProjectInTeamFormContext.types';
import { InvoicePaymentMethods } from '../../../../../../invoices/invoicesTypes';
import { ProjectNanoID, ProjectTeamNanoID } from '../../../../../projectsTypes';
import {
  TeamPreferredPaymentMethod,
  TeamTerms
} from '../../../../../../teams/teamsTypes';
import { UserID } from '../../../../../../users/usersTypes';
import { CreateProjectInTeamFormDataTaskItems } from '../../CreateProjectInTeamForm.types';
import { ItemTypeID } from '../../../../../../itemTypes/itemTypesTypes';

const CreateProjectInTeamFormContext =
  createContext<CreateProjectInTeamFormContextType>({
    formNanoId: null,
    control: null,
    createProjectInTeamLoading: false,
    currentUser: null,
    watchTaskItemsCount: (index) => {
      console.log('watchTaskItemsCount should be initialized');
      return 0;
    },
    getTaskItem: (taskIndex: number, itemIndex: number) => {
      console.log('getTaskItem should be initialized');
      return null;
    },
    handleCreateProjectAndInvoice: (e) => {
      console.log('handleCreateProjectAndInvoice should be initialized');
      return null;
    },
    handleCreateOnlyProject: (e) => {
      console.log('handleCreateOnlyProject should be initialized');
      return null;
    },
    handleCreateOnlyProjectWithoutItems: (e) => {
      console.log('handleCreateOnlyProjectWithoutItems should be initialized');
      return null;
    },
    register: null,
    registerDescription: null,
    registerImplementationDate: null,
    registerName: null,
    resetCreateProjectInTeamForm: () => {
      console.log('resetCreateProjectInTeamForm should be initialized');
      return null;
    },
    selectedBillingInfoId: null,
    selectedPaymentMethod: null,
    selectedGeneralLedgerId: null,
    selectedImplementationDate: null,
    selectedTeamNanoId: null,
    setOwnerId: (ownerId: UserID) => {
      console.log('setOwnerId should be initialized');
    },
    setBillingInfoId: (billingInfoId: BillingInfoID) => {
      console.log('setBillingInfoId should be initialized');
    },
    setNotes: (notes: AvBillingInfoNotes) => {
      console.log('setNotes should be initialized');
    },
    setTaskItemPrice: (
      taskIndex: number,
      itemIndex: number,
      itemPrices: {
        price: number;
        viewPrice: number;
        viewPriceCurrency: Currencies;
      }
    ) => {
      console.log('setTaskItemPrice should be initialized');
    },
    setTaskItemDescription: (
      taskIndex: number,
      itemIndex: number,
      itemDescription: string
    ) => {
      console.log('setTaskItemDescription should be initialized');
    },
    setPaymentMethod: (paymentMethod: InvoicePaymentMethods) => {
      console.log('setPaymentMethod should be initialized');
    },
    setTerms: (terms: TeamTerms) => {
      console.log('setTerms should be initialized');
    },
    trigger: null,
    watch: null,
    validationErrors: {
      nameValidationError: null,
      teamNanoIdValidationError: null,
      implementationDateValidationError: null,
      taskItemTypeIdValidationError: (taskIndex: number, itemIndex: number) => {
        console.log('taskItemTypeIdValidationError should be initialized');
        return null;
      },
      taskItemQtyValidationError: (taskIndex: number, itemIndex: number) => {
        console.log('taskItemQtyValidationError should be initialized');
        return null;
      },
      taskItemPriceValidationError: (taskIndex: number, itemIndex: number) => {
        console.log('taskItemPriceValidationError should be initialized');
        return null;
      },
      taskNameValidationError: (taskIndex: number) => {
        console.log('taskNameValidationError should be initialized');
        return null;
      }
    },
    watchTaskItemIds: (taskIndex: number): ItemTypeID[] => {
      console.log('watchTaskItemIds should be initialized');
      return null;
    },
    watchTaskItemSubtotal: (taskIndex, itemIndex): number => {
      console.log('watchTaskItemSubtotal should be initialized');
      return 0;
    },
    watchTaskTotal: (taskIndex) => {
      console.log('watchTaskTotal should be initialized');
      return 0;
    },
    watchProjectHasItems: false,
    watchProjectTotal: 0,
    handleChangeCurrency: (value) => {
      console.log('handleChangeCurrency should be initialized');
      return null;
    },
    currencyExchangeRate: 1,
    currencyPrefix: Currencies.USD,
    setCurrency: (value) => {
      console.log('setCurrency should be initialized');
    },
    companyCurrency: Currencies.USD,
    watchCurrency: Currencies.USD,
    watchSplitParts: () => [],
    setSplitPartValue: (partIndex: number, partValue: number) => {
      console.log('setSplitPartValue should be initialized');
    },
    setFocusSplitPart: (index: number) => {
      console.log('setFocusSplitPart should be initialized');
    },
    defaultTerms: '',
    defaultNotes: ''
  });

interface CreateProjectInTeamFormProviderProps {
  afterCreateProject: (projectNanoId: ProjectNanoID) => Promise<boolean>;
  children: ReactNode;
  initialItems?: CreateProjectInTeamFormDataTaskItems;
  ownerId?: UserID;
  preferredPaymentMethod: TeamPreferredPaymentMethod;
  teamNanoId: ProjectTeamNanoID;
  teamTerms: TeamTerms;
}

export function CreateProjectInTeamFormProvider({
  afterCreateProject,
  children,
  initialItems,
  ownerId,
  preferredPaymentMethod,
  teamNanoId,
  teamTerms
}: CreateProjectInTeamFormProviderProps) {
  const createProjectInTeamFormContext = useCreateProjectInTeamForm({
    afterCreateProject,
    initialItems,
    ownerId,
    preferredPaymentMethod,
    teamNanoId,
    teamTerms
  });

  return (
    <CreateProjectInTeamFormContext.Provider
      value={createProjectInTeamFormContext}
    >
      {children}
    </CreateProjectInTeamFormContext.Provider>
  );
}

export function useCreateProjectInTeamFormContext() {
  return useContext(CreateProjectInTeamFormContext);
}
