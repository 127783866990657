import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import { UserID, UserNanoID } from '../../../../../../../../users/usersTypes';

import {
  FETCH_SELECT_FIELD_USERS_QUERY,
  FetchSelectFieldUsersQueryResponse
} from '../../../../../../../../users/queries/fetchSelectFieldUsers.query';

import { usePaginatedUsers } from '../../../../../../../../users/hooks/usePaginatedUsers';

import { UsersAvatarsModalButton } from '../../../../../../../../users/components/modalButtons/UsersAvatarsModalButton';

import { tasksKeys } from '../../../../../../../../../locales/keys';

import { UserCache } from '../../../../../../../../users/UserCache';

type InvitedUser = {
  id: UserID;
};

type InvitedUsers = InvitedUser[];

interface CreateProjectTaskFormSelectedUsersProps {
  users: InvitedUsers;
  onRemoveUser: (id: UserID) => void;
  onRemoveAllUsers: () => void;
  disabled?: boolean;
}

function CreateProjectTaskFormSelectedUsers({
  users,
  onRemoveUser,
  onRemoveAllUsers,
  disabled
}: CreateProjectTaskFormSelectedUsersProps) {
  const ids = map(users, 'id');

  const {
    users: selectedUsers,
    usersFetched,
    usersFilters,
    changeUsersFilters
  } = usePaginatedUsers<FetchSelectFieldUsersQueryResponse>({
    cacheKey: UserCache.selectFieldSelectedUsersCacheKey(),
    query: FETCH_SELECT_FIELD_USERS_QUERY,
    initialFilters: {
      id: { in: ids }
    },
    initialLimit: 1000
  });

  useEffect(() => {
    if (!isEqual(ids, usersFilters?.id?.in)) {
      changeUsersFilters({ id: { in: ids } });
    }
  }, [ids, usersFilters, changeUsersFilters]);

  if (isEmpty(selectedUsers)) {
    return null;
  }

  return (
    <UsersAvatarsModalButton
      i18nModalTitle={tasksKeys.invitedUsers}
      users={selectedUsers.map((user) => ({
        ...user,
        nanoId: user.id as UserNanoID
      }))}
      allAvatarsSameSize
      avatarsCount={12}
      disabled={disabled}
      onRemoveUser={onRemoveUser}
      usersFetched={usersFetched}
      onRemoveAllUsers={async () => onRemoveAllUsers()}
    />
  );
}

export default CreateProjectTaskFormSelectedUsers;
