import React, { ReactNode } from 'react';

import { ClassName, I18nText, UUID } from '../../../../../types';
import {
  SmartContractShareIteration,
  SmartContractShareStatus
} from '../../../smartContractSharesTypes';

import { IconsEnum } from '../../../../../assets/icons/types';
import { CreateSmartContractShareFormView } from '../../forms/CreateSmartContractShareForm/CreateSmartContractShareForm.types';

import {
  CreateSmartContractShareFormDefaultUser,
  CreateSmartContractShareFormSubmitUser,
  useSmartContractSharesForm
} from '../../forms/CreateSmartContractShareForm/hooks/useSmartContractSharesForm';

import { CreateSmartContractShareForm } from '../../forms/CreateSmartContractShareForm';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { usersKeys, words } from '../../../../../locales/keys';

interface SmartContractShareFormModalButtonProps {
  invitedUserUuids?: UUID[];
  iteration?: SmartContractShareIteration;
  inviteClientsToSc?: boolean;
  inviteWorkersToSc?: boolean;
  status: SmartContractShareStatus;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  buttonChildren?: ReactNode;
  i18nTitle?: I18nText;
  tooltipI18nText?: I18nText;
  defaultUsers?: CreateSmartContractShareFormDefaultUser[];
  disabled?: boolean;
  onSubmit: (users: CreateSmartContractShareFormSubmitUser[]) => void;
}

const CREATE_SMART_CONTRACT_SHARE_FORM = 'create-smart-contract-share-form';

function SmartContractShareFormModalButton({
  invitedUserUuids,
  iteration,
  inviteClientsToSc,
  inviteWorkersToSc,
  status,
  className,
  icon,
  iconClassName,
  i18nText,
  i18nTextClassName,
  buttonChildren,
  i18nTitle = usersKeys.invite,
  tooltipI18nText,
  defaultUsers,
  disabled,
  onSubmit
}: SmartContractShareFormModalButtonProps) {
  const {
    control,
    setValue,
    formView,
    goToSelectUsersView,
    getValues,
    handleSubmitSmartContractShares,
    handleResetSmartContractSharesForm,
    disabledSubmit,
    oneFormView
  } = useSmartContractSharesForm({
    iteration,
    inviteClientsToSc,
    inviteWorkersToSc,
    status,
    defaultUsers,
    onSubmit
  });

  return (
    <SimpleModalButton
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      icon={icon}
      iconClassName={iconClassName}
      buttonChildren={buttonChildren}
      i18nSubmitText={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE ||
        oneFormView
          ? words.submit
          : words.next
      }
      i18nCancelText={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE
          ? words.back
          : words.cancel
      }
      i18nTitle={
        formView === CreateSmartContractShareFormView.SELECT_USERS && i18nTitle
      }
      tooltipI18nText={tooltipI18nText}
      onOpen={handleResetSmartContractSharesForm}
      closeOnCancel={formView === CreateSmartContractShareFormView.SELECT_USERS}
      onCancel={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE &&
        !oneFormView
          ? goToSelectUsersView
          : undefined
      }
      cancelButtonPosition={
        formView === CreateSmartContractShareFormView.CHANGE_SHARE
          ? 'start'
          : 'end'
      }
      onSubmit={handleSubmitSmartContractShares}
      submitDisabled={disabledSubmit}
      notHideOnSubmit={
        formView === CreateSmartContractShareFormView.SELECT_USERS &&
        !oneFormView
      }
      childrenClassName="overflow-y-auto relative"
      disabled={disabled}
    >
      <CreateSmartContractShareForm
        form={CREATE_SMART_CONTRACT_SHARE_FORM}
        control={control}
        formView={formView}
        goToSelectUsersView={
          formView === CreateSmartContractShareFormView.CHANGE_SHARE &&
          !oneFormView
            ? goToSelectUsersView
            : undefined
        }
        i18nTitle={i18nTitle}
        setValue={setValue}
        invitedUserUuids={invitedUserUuids}
        getValues={getValues}
        status={status}
      />
    </SimpleModalButton>
  );
}

export default SmartContractShareFormModalButton;
